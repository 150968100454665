import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider"];

  connect() {
    // console.log("connect::slider-controller");

    let maxWidthEnabled = this.sliderTarget.getAttribute("data-mobile");

    if (maxWidthEnabled) {
      maxWidthEnabled = parseInt(maxWidthEnabled);
    }
    else {
      maxWidthEnabled = 0;
    }

    if (maxWidthEnabled > 0 && window.innerWidth <= maxWidthEnabled) {
      this.sliderTarget.classList.add("swiper-container");
      this.sliderTarget.querySelector(".slider--wrapper").classList.add("swiper-wrapper");

      let slides = this.sliderTarget.querySelectorAll(".slider--slide");

      for (let slide of slides) {
        slide.classList.add("swiper-slide");
      }
    }

    if (maxWidthEnabled == 0 || maxWidthEnabled > 0 && window.innerWidth <= maxWidthEnabled) {
      this.setup();
    }
  }

  setup() {
    const slider = this.sliderTarget;
    const config = JSON.parse(slider.getAttribute("data-config"));

    let swiper;

    new imagesLoaded(slider, { background: true }, function() {
      swiper = new Swiper(slider, config);
    });
  }
}
