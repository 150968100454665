import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // console.log("connect::contact-form-controller");

    const el = this.element;
    const turboStream = el.querySelector("turbo_stream")

    turboStream.addEventListener("turbo:submit-start", function() {
      el.classList.add("sending");
    });

    turboStream.addEventListener("turbo:submit-end", (event) => {
      el.classList.remove("sending");

      if (event.detail.success) {
        el.classList.add("success");

        window.setTimeout(() => {
          el.classList.remove("success");

          let inputs = el.querySelectorAll("input, textarea");

          for (let input of inputs) {
            if (input.type == "submit") {
              continue;
            }

            if (input.type == "checkbox") {
              input.checked = false;
              continue;
            }

            input.value = "";
          }

        }, 6000);
      }
    });
  }
}
