import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    console.log("connect::cookies-modal-controller");

    this.cookieName = "nomiri_cookies_accepted";

    if (this.readCookie(this.cookieName) == undefined) {
      this.modalTarget.classList.add("present");
    } else {
      this.modalTarget.remove();
    }
  }

  acceptCookies() {
    this.createCookie(this.cookieName, "displayed", 365);
    this.modalTarget.classList.add("hide");
  }

  createCookie(name, value, days) {
    let config = "";

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      config = `; expires=${date.toGMTString()}; SameSite=Strict`;
    }

    document.cookie = `${name}=${value}${config}; path=/`;
  }

  readCookie(name) {
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); 
    return match ? match[1] : undefined;
  }
}
