import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["imagesSource", "viewer", "viewerImage"];

  connect() {
    // console.log("connect::image-viewer-controller");

    this.currentIndex = 0;

    this.numImages = this.imagesSourceTarget.querySelectorAll(".image-viewer-image").length;

    if (this.numImages <= 1) {
      this.viewerTarget.classList.add("-hide-btns")
    }
  }

  open(evt) {
    if (this.setImage(evt.target)) {
      document.body.classList.add("lock-scroll");
      this.viewerTarget.classList.add("show");
    }
  }

  close() {
    document.body.classList.remove("lock-scroll");
    this.viewerTarget.classList.remove("show");
    this.viewerImageTarget.setAttribute("src", "");
    this.currentIndex = 0;
  }

  prev() {
    this.currentIndex -= 1;

    if (this.currentIndex < 0) {
      this.currentIndex = this.numImages - 1;
    }

    this.selectImage(this.currentIndex);
  }

  next() {
    this.currentIndex += 1;

    if (this.currentIndex >= this.numImages) {
      this.currentIndex = 0;
    }

    this.selectImage(this.currentIndex);
  }

  selectImage(index) {
    let source = this.imagesSourceTarget.querySelector(`.image-viewer-image-${index}`);

    if (!this.setImage(source)) {
      this.close();
    }
  }

  setImage(source) {
    try {
      let img = source.getAttribute("data-url") || source.getAttribute("src");
      this.viewerImageTarget.setAttribute("src", img);
      this.currentIndex = parseInt(source.getAttribute("data-index"));

      return true;

    } catch {
      return false;
    }
  }
}
