const images = require.context('../images', true);
// const imagePath = (name) => images(name, true);

// const imagePath = (name) => images(name, true)
import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

// JS
import "bootstrap";
import "bootstrap-datepicker";
import "jquery";
import "spectrum-colorpicker";
// import "@fortawesome/fontawesome-free/js/all";

// Stylesheets
import "../src/normalize.css";
import "../src/app.scss";
import "../src/web/web.scss";
import "../src/web/spec_page.scss";
import "../src/web/topbar.scss";
import "../src/web/fade_block.scss";
import "../src/web/image_viewer.scss";
import "../src/web/contact_form.scss";
import "../src/web/footer.scss";
import "../src/web/cookies_modal.scss";

// ATM we only need it for the styler.
import Rails from 'rails-ujs';
Rails.start();

// Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import ScrollReveal from "stimulus-scroll-reveal";

const application = Application.start();
const context = require.context("../src/web/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
application.register("scroll-reveal", ScrollReveal);

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

/*
  Scroll suave a un anchor.
*/
function scrollAnchors(e, respond = null) {
  e.preventDefault();

  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  let targetID, targetAnchor;

  targetID = (respond) ? respond.getAttribute("href") : this.getAttribute("href");

  if (targetID && targetID != "#") {
    targetAnchor = document.querySelector(targetID);
  }

  if (targetAnchor) {
    const originalTop = distanceToTop(targetAnchor);

    window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

    const checkIfDone = setInterval(function() {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = "-1";
        targetAnchor.focus();
        window.history.pushState("", "", targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }
}

let prevWindowWidth = window.innerWidth;

document.addEventListener("DOMContentLoaded", function() {
  // scroll suave
  const links = document.getElementsByTagName("a");

	for (let i = 0; i < links.length; i++) {
		const link = links[i];

    if (
          (link.href && link.href.indexOf("#") != -1)
          &&
          (link.pathname == location.pathname || ('/' + link.pathname == location.pathname && link.search == location.search))
        )
    {
      link.onclick = scrollAnchors;
    }
  }

  if (document.querySelector(".page")) {
    let bodyId = document.querySelector(".page").id;
    let watchedPages = ["webHomeIndex"];

    if (watchedPages.indexOf(bodyId) > -1) {
      window.addEventListener("resize", debounce(function() {
        let windowWidth = window.innerWidth;

        if ((prevWindowWidth <= 1024 && windowWidth > 1024) || (prevWindowWidth > 1024 && windowWidth <= 1024)) {
          prevWindowWidth = windowWidth;
          location.reload();
        }
      }, 25));
    }
  }
});
